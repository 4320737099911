.slider_img_one {
  background: rgba(0, 0, 0, 0.3);
  background-blend-mode: overlay;
  background-image: url("../../assets/images/slider/slider-img-6.jpg");
  width: 100%;
  height: 90vh;
  background-position: center center;
  background-size: cover;
}
.slider_img_two {
  background: rgba(0, 0, 0, 0.3);
  background-blend-mode: overlay;
  background-image: url("../../assets/images/slider/slider-img-5.jpg");
  width: 100%;
  height: 90vh;
  background-position: center center;
  background-size: cover;
}
.slider_img_three {
  background: rgba(0, 0, 0, 0.3);
  background-blend-mode: overlay;
  background-image: url("../../assets/images/slider/slider-img-3.jpg");
  width: 100%;
  height: 90vh;
  background-position: center center;
  background-size: cover;
}
.slidercontent {
  margin-bottom: 220px;
  color: #fff;
}
.slidercontent h2 {
  padding-bottom: 15px;
  font-weight: 700;
}

@media screen and (max-width: 480px) {
  .slider_img_one,
  .slider_img_two,
  .slider_img_three {
    width: 100%;
    height: 100%;
  }
  .slidercontent {
    margin-bottom: 280px;
  }
  .slidercontent h2 {
    padding-bottom: 0px;
  }
}
