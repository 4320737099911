/* .header_top {
  height: 40px;
  display: flex;
  background: #000;
  color: rgba(255, 255, 255, 0.562);
}
.social_link {
  display: flex;
  justify-content: end;
  align-items: center;
  line-height: 40px;
} */
.padding-top {
  padding-top: 40px;
}
.dropdown-menu {
  top: 60px !important;
}
.nav-bg {
  background: transparent;
  color: #000;
}
.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-navbar-brand-color) !important;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
