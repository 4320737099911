.footer_section {
  background: #f1f1f1;
  color: #000000;
}
.footer_top {
  padding: 80px 0px;
}
.page_wrapper {
  width: 100%;
  height: 100%;
  background: rgb(143, 143, 143);
}
.footer_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: rgb(27, 27, 27);
  color: #fff;
}
.footer_bottom p {
  margin-top: 15px;
}

.footer_bottom a {
  flex-grow: 1;
  color: #0070f3;
  text-decoration: none;
  font-weight: bold;
}
