/* Home Page Style */
.about_company {
  padding: 120px 0px;
}

/* Approved Certificate Section */
.approved_certificate {
  padding: 120px 0px;
  background: #f3f3f3;
}
.certificate {
  height: 120px;
  width: 100%;
  background: rgb(255, 255, 255);
  padding: 8px;
  border-radius: 8px;
}
.certificate img {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}
.content_wrapper {
  background: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  padding: 80px 8px;
}
.content_wrapper h2 {
  text-align: center;
}
/* creative message Section */
.creative_message_section {
  padding: 120px 0px;
}
.creative_message_top {
  text-align: center;
}
.creative_message_top h2 {
  margin-bottom: 12px;
  font-size: 3.5rem;
}
.creative_message {
  margin-top: 15px;
  height: 400px;
  width: 100%;
  background: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.creative_message h5 {
  text-align: center;
  font-size: 2rem;
  padding: 5px;
}
/* Counter Section */
.counter_section {
  padding: 80px 0px;
  background: #f1f1f1;
  text-align: center;
}
.counter_title h1 {
  margin-bottom: 40px;
  font-weight: 700;
}
.counter_content h1 {
  font-weight: 700;
}
/* Home Page Buyer Logo */
/* .img_wrapper img {
  width: 120px;
  height: 60px;
} */
.img_wrapper {
  /* background: rgba(255, 255, 255, 0.2); */
  background: transparent;
  display: flex;
  align-items: center;
  max-width: 250px;
  width: 200px;
  height: 150px;
  /* border: 1px solid #f7f7f7; */
  border-radius: 5px;
  box-shadow: rgb(149 157 165 / 0%) 0px 8px 24px;
  overflow: hidden;
}
.img_wrapper img {
  width: 100%;
  height: auto;
  padding: 20px;
  transition: all 0.3s ease-in-out;
}
.img_wrapper:hover img {
  transform: scale(1.1);
  cursor: pointer;
}
/* Our Client Style */
.our_client {
  padding: 80px 0px;
  background: #ffffff;
  width: 100%;
}
.our_client_title h3 {
  text-align: center;
  padding-bottom: 40px;
  font-weight: 700;
}
/* Our Services */
.service_bg {
  background: rgba(0, 0, 0, 0.8);
  background-blend-mode: overlay;
  background-image: url("../assets/images/service_to_bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service_bg h3 {
  color: #fff;
}
.service_content {
  background: rgb(0, 0, 0);
  background-blend-mode: screen;
  /* background-image: url("https://images.unsplash.com/photo-1494380436044-bae026289762?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTA1fHxsZWZ0JTIwaGFuZCUyMGZpbmdlcnxlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60"); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0px;
  color: #fff;
}

/* Company Profile */
.company_profile_header_bg {
  background: rgba(15, 15, 15, 0.6);
  background-blend-mode: overlay;
  background-image: url("../assets/images/slider/slider-img-1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.company_overview {
  background: #000;
  background-blend-mode: overlay;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company_profile {
  padding: 80px 0px;
  background: #0f0f0f;
  color: #fff;
}
.company_profile p {
  font-size: 1.125rem;
}
/* Company Vision And Mission */
.company_vision {
  padding: 80px 0px;
  background: #0f0f0f;
  background-blend-mode: overlay;
  background-image: url("https://images.unsplash.com/photo-1502217915754-8272dd84a805?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDV8fG5lZWRsZXxlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
}
.company_vision h3 {
  font-weight: bold;
}
.company_vision p {
  font-size: 1.125rem;
}
/* Our Management */
.our_management_bg {
  height: 100%;
  width: 100%;
  background: #000;
  color: #fff;
  padding: 80px 0px;
}
.bi-quote {
  color: #494949;
}
.ceo_img_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ceo_img_wrapper img {
  width: 380px;
  height: 400px;
  z-index: 9999;
}
.img_border {
  position: absolute;
  background: #0c0c0c;
  top: 100px;
  left: 270px;
  border: 3px solid #636363;
  height: 350px;
  width: 300px;
}
/* Products Gallery */
.mens_header_bg {
  background: rgba(0, 0, 0, 0.8);
  background-blend-mode: overlay;
  background-image: url("../assets/images/slider/slider-img-1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mens_header_bg h3 {
  color: #fff;
}
.mens_content {
  padding: 60px 0px;
}
.gallery_img {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.gallery_img img {
  width: 100%;
  height: 250px;
  cursor: pointer;
  opacity: 0.9;
  transition: all ease-in-out 0.3s;
}
.gallery_img img:hover {
  transform: scale(1.02);
  opacity: 1;
}
/* Womens Products */
.womens_content {
  padding: 60px 0px;
}
/* Quality */
.quality_bg {
  background: #131313;
  background-blend-mode: overlay;
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quality_bg h3 {
  color: #fff;
}
.quality_content {
  background: rgb(255, 255, 255);
  background-blend-mode: overlay;
  /* background-image: url("https://images.unsplash.com/photo-1494380436044-bae026289762?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTA1fHxsZWZ0JTIwaGFuZCUyMGZpbmdlcnxlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; */
  padding: 80px 0px;
  color: rgb(0, 0, 0);
}
/* Compliance */
.compliance_bg {
  background: #131313;
  background-blend-mode: overlay;
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.compliance_bg h3 {
  color: #fff;
}
.compliance_content {
  background: rgb(0, 0, 0);
  background-blend-mode: overlay;
  /* background-image: url("https://images.unsplash.com/photo-1494380436044-bae026289762?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTA1fHxsZWZ0JTIwaGFuZCUyMGZpbmdlcnxlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; */
  padding: 80px 0px;
  color: #fff;
}

/* Contact US */
.contact {
  padding: 80px 0px;
  background: #292929;
  color: #fff;
}
.google_map img {
  width: 100%;
  height: 60vh;
}

/* Responsive For Mobile And Table */
@media screen and (max-width: 480px) {
  .certificate {
    height: 80%;
    width: 100%;
    margin-top: 10px;
  }
  .certificate img {
    height: 80%;
    width: 80%;
    margin-top: 10px;
  }
  .img_border {
    display: none;
  }
  .ceo_img_wrapper img {
    margin-top: 20px;
  }
}
